<template>
    <div class="flex-box buddhism-bench">
        <div class="flex-grow flex-box vertical">
            <div class="piece-area buddhism-my" v-if="admin">
                <div class="piece-area-title">总览</div>
                <div class="piece-area-ctx flex-box align-center">
                    <div class="buddhism-order-view">
                        <div class="buddhism-order-title">待审核</div>
                        <div class="buddhism-order-num">{{count.not_verify}}</div>
                    </div>
                    <div class="buddhism-order-view">
                        <div class="buddhism-order-title">待派单</div>
                        <div class="buddhism-order-num">{{count.not_distribute}}</div>
                    </div>
                </div>
            </div>
            <div class="piece-area buddhism-my" v-else>
                <div class="piece-area-title">总览</div>
                <div class="piece-area-ctx flex-box align-center">
                    <div class="buddhism-order-view">
                        <div class="buddhism-order-title">待开牌</div>
                        <div class="buddhism-order-num">{{count.not_kp}}</div>
                    </div>
                    <div class="buddhism-order-view">
                        <div class="buddhism-order-title">已开牌</div>
                        <div class="buddhism-order-num">{{count.kp}}</div>
                    </div>
                </div>
            </div>
            <buddhism-enroll-list ref="buddhismList" :type="buddhismType" class="piece-area buddha-order-list"></buddhism-enroll-list>
        </div>
        <div class="flex-box vertical buddhism-bench-right" v-if="admin">
            <a-card :bordered="false" class="buddhism-bench-card" title="线下登记">
                <router-link slot="extra" :to="{name: 'BuddhismAdd'}">去登记<a-icon type="right" /></router-link>
                <div class="buddhism-qr-list">
                    <div class="buddhism-qr-item" v-for="(q, i) in qrList" :key="i">
                        <img :src="q.img" alt="" class="buddhism-qr-img" v-if="q.img">
                        <div class="buddhism-qr-img flex-box align-center justify-center" v-else><a-spin /></div>
                        <div class="buddhism-qr-title">{{q.title}}</div>
                    </div>
                </div>
            </a-card>
            <a-card :bordered="false" class="flex-grow buddhism-bench-card" :body-style="{padding: 0}" title="认领台">
                <router-link slot="extra" :to="{name: 'BuddhismClaim'}">查看更多<a-icon type="right" /></router-link>
                <div class="buddhism-claim-list" v-if="claimList && claimList.length > 0">
                    <div class="buddhism-claim-item" v-for="b in claimList" :key="b.id">
                        <div class="buddhism-item-name">{{b.temp ? b.temp.name : '--'}}</div>
                        <div class="buddhism-item-way">{{b.wayText}}</div>
                        <div class="buddhism-item-btn"><span class="txt-btn" @click="showDetail(b)">查看</span></div>
                    </div>
                </div>
                <a-empty :image="emptyImage" v-else-if="claimList"/>
            </a-card>
            <a-card :bordered="false" class="flex-grow buddhism-bench-card" :body-style="{padding: 0}" title="未读消息">
                <router-link slot="extra" :to="{name: 'BuddhaNotice'}">查看更多<a-icon type="right" /></router-link>
                <notice-list simple></notice-list>
            </a-card>
        </div>
    </div>
</template>

<script>
    import BuddhismEnrollList from "./BuddhismEnrollList";
    import NoticeList from "../../layouts/NoticeList";
    import {getBuddhaRoles, isBuddhaAdmin} from "../../common/constant/org";
    import {getUserWxId,getTemple} from "../../common/js/storage";
    import { Empty } from 'ant-design-vue';
    import {getBuddhismWayText} from "../../common/buddha/buddhism";
    import config from "../../common/js/config";

    export default {
        name: "BuddhismWorkbench",
        components: {
            NoticeList,
            BuddhismEnrollList
        },
        data() {
            return {
                admin: isBuddhaAdmin(),
                claimList: null,
                emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,
                count: {
                    kp: 0,
                    not_distribute: 0,
                    not_kp: 0,
                    not_verify: 0
                },
                qrList: [
                    { title: '客堂内部登记码', img: 'https://lingyin-1301841918.cos.ap-shanghai.myqcloud.com/assets/buddha/qr.jpg' },
                ]
            }
        },
        computed: {
            buddhismType() {
                const roles = getBuddhaRoles();
                let res = "myOpen";
                if (roles.includes(1) || roles.includes(2)) {
                    res = "";
                } else if(roles.includes(2)) {
                    res = "open"
                }
                return res;
            }
        },
        created() {
            if(this.admin) {
                this.getClaimList();
            }
            this.getData();
            // this.getPfQr();
            // this.getSxQr();
        },
        methods: {
            getSxQr() {
                this.$axios({
                    url: '/mini/common/share-code',
                    method: 'POST',
                    data: {
                        path: "pages/buddhism/buddhism?sx=1"
                    }
                }).then(res => {
                    const url = `${config.host}/${res.data}`;
                    this.$set(this.qrList[2], 'img', url);
                })
            },
            getPfQr() {
                this.$axios("/admin/fs-temp?pageSize=1&filter[fs_type]=1").then(res => {
                    const item = res.data[0];
                    if (item) {
                        this.$axios({
                            url: '/mini/common/share-code',
                            method: 'POST',
                            data: {
                                path: `pages/buddhism/pufo-reserve/pufo-reserve?id=${item.id}`
                            }
                        }).then(res => {
                            const url = `${config.host}/${res.data}`;
                            this.$set(this.qrList[1], 'img', url);
                        })
                    }
                })
            },
            getData() {
                let url = '/admin/fs/get-count';
                this.$axios(url).then(res => {
                    if(res.error == 0) {
                        this.count = res.data
                    } else {
                        this.$message.warning(res.msg);
                    }
                })
            },
            claimBuddhism(b) {
                this.$confirm({
                    title: '提示',
                    content: '确定认领吗？',
                    onOk: () => {
                        let url = `/admin/fs/${b.id}`;
                        let wxId = getUserWxId();
                        this.$axios({
                            url,
                            method: 'PATCH',
                            data: {
                                user_id: wxId,
                                status: 2,
                                flow: JSON.stringify([{type: 1, title: '已认领', user_id: wxId, time: new Date().pattern("yyyy-MM-dd: HH:mm:ss")}])
                            }
                        }).then(() => {
                            this.$message.success("已认领");
                            this.getClaimList();
                            this.$refs.buddhismList.getList();
                        });
                    },
                });
            },
            showDetail(b) {
                this.$router.push({name: 'BuddhismDetail', params: {id: b.id}});
            },
            getClaimList() {
                let url = `/admin/fs?sort=-id&expand=temp&pageSize=10&status=1&temple_id=${getTemple()}&is_rl=1`;
                this.$axios(url, {noTempleFilter: true}).then(res => {
                    res.data.forEach(item => {
                        item.wayText = getBuddhismWayText(item);
                    })
                    this.claimList = res.data;
                });
            }
        }
    }
</script>

<style scoped lang="less">
    .buddhism-bench {
        flex-shrink: 0;
        margin: 16px auto;
        width: 1400px;
        min-height: 818px;
    }
    .piece-area {
        padding: 16px;
        background-color: @component-background;
        box-shadow: @box-shadow-base;
        border-radius: 2px;
        &.buddha-order-list {
            flex: 1;
            border-radius: 2px;
        }
    }
    .piece-area-title {
        padding-bottom: 16px;
        border-bottom: var(--border);
        font-size: 16px;
        line-height: 1;
    }
    .buddhism-qr-list {
        display: flex;
        justify-content: center;
    }
    .buddhism-qr-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100px;
    }
    .buddhism-qr-img {
        width: 120px;
        height: 120px;
    }
    .buddhism-order-view {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 16px 0;
        align-items: center;
        font-size: 16px;
    }
    .buddha-order-list {
        margin: 16px 0 0;
        width: auto;
    }
    .buddhism-bench-right {
        flex-shrink: 0;
        margin-left: 16px;
        width: 360px;
        gap: 16px;
    }
    .buddhism-register {
        margin-top: 16px;
    }
    .buddhism-bench-card {
        box-shadow: @box-shadow-base;
    }
    .buddhism-claim-item {
        display: flex;
        align-items: center;
        padding: 8px 24px;
        &:hover {
            background-color: @background-color-light;
        }
    }
    .buddhism-item-name {
        flex: 1;
    }
    .buddhism-item-way {
        width: 120px;
    }
</style>
